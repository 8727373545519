@import "react-vertical-timeline-component/style.min.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.app__experience {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.mainDiv {
  margin-top: 2rem;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.timeline-icon_holder{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline-icon {
  width: 90%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.app__experience-container {
  width: 80%;
  margin-top: 3rem;

  display: flex;
  flex-direction: row;

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
}

.text-white {
  color: #fffafa;
  font-family: "Poppins", sans-serif;
}

.text-date {
  font-family: "Poppins", sans-serif;
  color: var(--black-color);
}

.text-company{
  font-size: 16px;
  font-weight: 600;
  color: rgb(170, 166, 196);
}

.text-24 {
  font-size: 24px;
}

.font-bold {
  font-weight: bold;
}

.text-14 {
  font-size: 14px;
}

.pl-1 {
  padding-left: 0.25rem;
}

.tracking-wider {
  letter-spacing: 0.05em;
}

.list-disc {
  list-style-type: disc;
}

.ml-5 {
  margin-left: 1.25rem;
}

.space-y-2 {
  margin-bottom: 0.75rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.mt-20 {
  margin-top: 5rem;
}

.text-center {
  text-align: center;
}

.text-30 {
  font-size: 30px;
}

.md\:text-60 {
  @media (min-width: 768px) {
    font-size: 60px;
  }
}

.sm\:text-50 {
  @media (min-width: 640px) {
    font-size: 50px;
  }
}

.xs\:text-40 {
  @media (min-width: 480px) {
    font-size: 40px;
  }
}